<template>
  <div class="church-ministry template-1">
    <page-header class="mx-lg">
      <h1>{{ translations.tcConversationsHome }}</h1>
    </page-header>
    <camp-selector @camp_changed="page_load($event)" :showCamp="false" :tooltip="translations.tcCurrentHome"
      :i18n="translations.components" />
    <div class="page-body container mx-med">
      <section class="section-1">
        <h4>{{ translations.tcUpcomingConversationsEvent }}</h4>
        <div class="row">
          <div class="col col-6" v-if="translations.components">
            <div v-if="this.widget_data.upcomingConversation.length == 0">
                {{ translations.tcNoUpcomingConversationsMessage }}
            </div>
            <div v-else>
              <DateCard v-for="(conversation, index) in widget_data.upcomingConversation" :key="index"
                :obj="conversation.date" :i18n="translations.common">
                <BasicDateCard :i18n="translations.components" :obj="conversation"
                  @date_card_clicked="handleDateCardClick($event)" />
              </DateCard>
            </div>
            <div class="mt-3">
              <b-button @click="handleEventViewMoreClick(false)" variant="primary" class="more mr-3">
                {{ translations.tcViewMore }}
              </b-button>
              <b-button v-if="conversationsICanSee(secured_controls.add_conversation_button)" @click="handleEventAddClick" variant="primary" class="more">
                {{ translations.tcAddConversation }}
              </b-button>
            </div>
          </div>
          <div class="col col-6">
            <b-skeleton-wrapper :loading="loading.event">
              <template #loading>
                <b-card>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </b-card>
              </template>
              <NumberCard v-for="(stat, index) in topWidgetData" :key="index" :obj="stat"
                :i18n="translations.components" />
            </b-skeleton-wrapper>
          </div>
        </div>
      </section>
      <section class="section-1">
        <h4>{{ translations.tcPastConversationsEvent }}</h4>
        <div class="row">
          <div class="col col-6" v-if="translations.components">
            <div v-if="this.widget_data.pastConversation.length == 0">
                {{ translations.tcNoPastConversationsMessage }}
            </div>
            <div v-else>
              <DateCard v-for="(conversation, index) in widget_data.pastConversation" :key="index"
                :obj="conversation.date" :i18n="translations.common">
                <BasicDateCard :i18n="translations.components" :obj="conversation"
                  @date_card_clicked="handleDateCardClick($event)" />
              </DateCard>
            </div>
            <div class="mt-3">
              <b-button @click="handleEventViewMoreClick(true)" variant="primary" class="more mr-3">
                {{ translations.tcViewMore }}
              </b-button>
              <b-button v-if="conversationsICanSee(secured_controls.add_conversation_button)" @click="handleEventAddClick" variant="primary" class="more">
                {{ translations.tcAddConversation }}
              </b-button>
            </div>
          </div>
          <div class="col col-6">
            <b-skeleton-wrapper :loading="loading.event">
              <template #loading>
                <b-card>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </b-card>
              </template>
              <!--
                  Commented out until we know what data we want to show
                <NumberCard v-for="(stat, index) in topWidgetData" :key="index" :obj="stat"
                :i18n="translations.components" /> -->
            </b-skeleton-wrapper>
          </div>
        </div>
      </section>
      <section class="section-3">
        <div class="row">
          <template v-for="(info, index) in cardInfo" >
            <InfoCard :obj="info" :key="index"
              @set_program="handleSetProgram($event)"
              v-if="info.secured_controls_id === '' || conversationsICanSee(info.secured_controls_id)"
              />
          </template>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BasicDateCard from '@/components/date-card/Basic.vue'
import { calendarMixin } from '@/mixins/calendarMixin'
import CampSelect from '@/components/CampSelect.vue'
import DateCard from '@/components/date-card/DateCard.vue'
import InfoCard from '@/components/info-card/InfoCard.vue'
import NumberCard from '@/components/number-card/NumberCard.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'conversations-landing-page',
  mixins: [calendarMixin, translationMixin, securityMixin],
  data() {
    return {
      translations: {},
      conversations: [],
      loading: {
        event: false,
        stat: false
      },
      cardInfo: [
        {
          class: 'col col-6',
          title: 'Educational Units',
          content: `View a list of all Conversation Educational Units in your state.`,
          link: '/programs/pw/conversations/educational-units',
          label: 'View More',
          secured_controls_id: 'f5f1bec6-e280-4a2e-bc40-146d54ab6e2f',
          img: {
            src: 'svgs/church-officers.svg',
            alt: 'Educational Unit List',
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: 'Instructors',
          content: 'View a list of all Conversation Instructors in your state.',
          link: '/programs/pw/conversations/instructors',
          label: 'View More',
          secured_controls_id: '129BA415-307B-4036-8D12-3535E8B2042B',
          img: {
            src: 'svgs/church-officers.svg',
            alt: 'Instructors List',
            width: 64,
            height: 64,
          },
        },
        //Commented out bc it was asked to remove, leaving in just in case they want to put it back
        // {
        //   class: 'col col-6',
        //   title: 'Program Resources',
        //   content: 'View manuals, videos, and other resources for Conversations.',
        //   emitlink: '/resources/media-portal/program',
        //   itemId: '7c5109fd-5360-403e-9e67-260e31d95919', // The sitecore ItemId for Conversations Program
        //   secured_controls_id: '',
        //   label: 'View More',
        //   img: {
        //     src: 'svgs/program-resources.svg',
        //     alt: 'Program Resources',
        //     width: 64,
        //     height: 64,
        //   },
        // },
        {
          class: 'col col-6',
          title: 'Training Materials',
          content: 'View manuals, videos, and other resources for Conversations Training Materials.',
          emitlink: '/resources/media-portal/program',  //NOTE: This might need updated when MRP is set up
          itemId: 'c525f200-4ee7-49a6-9bda-50da05a4014a', // The sitecore ItemId for Conversations Program Training Materials
          secured_controls_id: 'a5ba996e-f8d7-4319-b58d-cb3d73dd48b8',
          label: 'View More',
          img: {
            src: 'svgs/program-resources.svg',
            alt: 'Training Materials',
            width: 64,
            height: 64,
          },
        },
      ],
      secured_controls: {
        add_conversation_button: 'c530dd36-6a4b-43c3-8b55-bc5da53644c4',
        training_material_tile: 'a5ba996e-f8d7-4319-b58d-cb3d73dd48b8',
        instructors_tile: '129BA415-307B-4036-8D12-3535E8B2042B'
      },
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('basic-date-card', 'camp-select', 'date-card', 'number-card', 'security-banner'),
        this.getComponentTranslations(
          'common.attire',
          'common.church-service-type',
          'common.days',
          'common.months-abbreviations'
        )
      ]).then(results => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)
        this.loadTranslations()
      })
      await this.page_load()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      clearConversation: 'conversations/clearConversation',
      loadWidgetData: 'conversations/loadWidgetData',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPProgramId: 'mrp/setMRPProgramId',
      setSelectedMeetingKey: 'user/setSelectedMeetingKey',
      updateConversationStateCountry: 'conversations/updateConversationStateCountry',
    }),

    loadTranslations() {
      //this.topWidgetData[0].title = this.translations.tcNumberConversationsPast12Months
      this.cardInfo[0].title = this.translations.tcEducationalUnitTitle
      this.cardInfo[0].content = this.translations.tcEducationalUnitDescription
      this.cardInfo[0].label = this.translations.tcViewMore
      this.cardInfo[1].title = this.translations.tcInstructorsTitle
      this.cardInfo[1].content = this.translations.tcInstructorsDescription
      this.cardInfo[1].label = this.translations.tcViewMore
      // this.cardInfo[2].title = this.translations.tcProgramResourcesTitle
      // this.cardInfo[2].content = this.translations.tcProgramResourcesDescription
      // this.cardInfo[2].label = this.translations.tcViewMore
      this.cardInfo[2].title = this.translations.tcTrainingMaterialsTitle
      this.cardInfo[2].content = this.translations.tcTrainingMaterialsDescription
      this.cardInfo[2].label = this.translations.tcViewMore
    },
    async page_load(evt) {
      if (!evt) {
        this.loading.event = true
        this.loading.stat = true
        const payload = {
          org_key: this.officerToolbarSelected.country_state,
          row_count: 3
        }
        await this.loadWidgetData(payload).then(() => {
          this.loading.event = false
        })
          .catch(err => {
            console.error(err)
          })
        // await this.checkPermissions() PUT BACK IN LATER
        //await this.getAnnouncementMessages(this.officerToolbarSelected.country_state)
        // await this.loadMeetings()
        //   .then(() => {
        //     this.loading.event = false
        //   })
        //   .catch(err => {
        //     console.error(err)
        //   })
        // let church_visited = await this.getChurchVisited()
        //   .then()
        //   .catch(err => {
        //     console.error(err)
        //   })
        // this.yearataglance = statedata
        // if (this.dataincircle.length > 0) this.dataincircle = []
        // this.yearataglance.yearataglance.forEach(item => {
        //   if (item.Stat_Level === 'S') {
        //     this.dataincircle.push(item)
        //   }
        // })
        // if (church_visited.count !== null) {
        //   this.topWidgetData = [
        //     {
        //       num: Math.round((church_visited.count[0].subtotal / church_visited.count[0].total) * 100) + '%',
        //       title:
        //         this.translations.tcPercentageOfChurchesVistedPast12Months ||
        //         'PERCENTAGE OF CHURCHES VISITED PAST 12 MONTHS',
        //       subtitle: ''
        //     }
        //   ]
        // }
        // this.generateStats(this.dataincircle)
      }
    },
    async checkPermissions() {
      await this.getControlPermissionsFromList(Object.values(this.secured_controls)).then(() => {
        // this.churchMinistryInfo[0].display = this.determineAccessibility(
        //   this.secured_controls.state_treasury_tools_tile
        // )
        // this.churchMinistryInfo[1].display = this.determineAccessibility(this.secured_controls.state_goal_planner_tile)
        // this.churchMinistryInfo[2].display = this.determineAccessibility(
        //   this.secured_controls.state_security_manager_tile
        // )
        // this.churchMinistryInfo[6].display = this.determineAccessibility(
        //   this.secured_controls.state_secure_reports_tile
        // )
      })
    },
    async handleDateCardClick(evt) {
      await this.setSelectedMeetingKey(evt.cvs_mtg_key)
      this.$router.push(evt.destination)
    },
    handleEventViewMoreClick(past = false) {
      //this.$router.push({ name: 'conversation-list' })
      this.$router.push(past ? 'conversations/list#pastconversations' : 'conversations/list')

    },
    async handleEventAddClick() {
      await this.clearConversation()
      const country = this.officerToolbarCountriesStates.find(
        sf => sf.value === this.officerToolbarSelected.country_state
      )
      await this.updateConversationStateCountry({ ...country })
      this.$router.push({ name: 'conversations-add' })
    },
    async handleSetProgram(data) {
      this.setMRPProgramId(data.itemId)
      this.$router.push(data.emitlink)
    },
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      officerToolbarCountriesStates: 'user/officerToolbarCountriesStates',
      officerToolbarSelected: 'user/officerToolbarSelected',
      widget_data: 'conversations/widget_data',
    }),
    topWidgetData() {
      return [
        {
          num: this.widget_data.conversationCounts,
          title: this.translations.tcNumberConversationsPast12Months,// 'Conversations in the past 12 month',
          subtitle: ''
        },
        {
          num: this.widget_data.attendeeCounts,
          title: this.translations.tcNumberAttendeesPast12Months, //'Attendees in the past 12 month',
          subtitle: ''
        },
      ]
    },
  },
  mounted() { },
  components: {
    BasicDateCard: BasicDateCard,
    campSelector: CampSelect,
    DateCard: DateCard,
    NumberCard: NumberCard,
    InfoCard: InfoCard,
    pageHeader: pageHeader
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.statelanding {
  text-align: center;
}

.church-ministry {
  .page-body {
    margin-top: 68px;

    @include breakpoint(sm) {
      margin-top: 3rem;
    }

    h4 {
      margin-bottom: 36px;
    }
  }

  .btn {
    padding: 12px 35px;
    border: none;
    border-radius: 0;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 22px;
    text-transform: uppercase;
  }

  .btn.btn-primary:hover,
  .btn.btn-primary:active,
  .btn.btn-secondary {
    background-color: #fbc301;
  }

  .btn.btn-primary {
    background-color: #003946;
  }

  .section-1,
  .section-2 {
    margin-bottom: 80px;

    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }

    >.row {
      margin-bottom: 28px;
    }

    .col {
      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;
      }

      @include breakpoint(sm) {
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
  .section-3 {
    margin-bottom: 60px;
    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }
      &:nth-child(2n) {
        padding-left: 23px;
      }
    }
    .number-card {
      align-items: center;
    }
    .row {
      justify-content: center;
    }
    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
  .more {
    width: auto !important;
    margin: 30px 0 30px 0;
  }

  .ann-text {
    color: #747474;
    font-family: 'Lora', serif;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 36px;
    margin-bottom: 42px;
    height: 200px;
    overflow: auto;
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
